<template>
  <div class="show-main">
      <div class="show-main-header">
        <el-button @click="selected_display_status=false" style="margin-right:5px;" type="danger">תצוגות שממתינות לאישור</el-button>
        <el-button @click="selected_display_status=true"  style="margin-right:5px;" type="success">תצוגות שאושרו</el-button>
      </div>
      <div class="show-main-content">
          <table>
            <tr>
                <th>מק"ט</th>
                <th>שם סוכן</th>
                <th>מס סוכן</th>
                <th>תאריך</th>
                <th>סטטוס</th>
                <th>תמונה</th>
                <th>פעולות</th>
            </tr>
            <template v-for="product in products" :key="product.makat">
                <template v-for="(show,index) in product.shows" :key="index">
                    <tr v-if="show.approved == selected_display_status">
                        <td>{{product.makat}}</td>
                        <td>{{show.agent_name}}</td>
                        <td>{{show.agent_id}}</td>
                        <td>{{new Date(show.created_at.seconds * 1000).toLocaleDateString('he')}}</td>
                        <td v-if="!show.approved" style="color:red;">ממתין לאישור</td>
                        <td v-if="show.approved" style="color:green;">אושר</td>
                        <td>
                            <img style="width:100px;" :src="show.url">
                        </td>
                        <td>
                            <i @click="handle_edit_show(product,show)" class="material-icons icon edit-icon ">edit</i>
                            <i @click="handle_delete_show(product,show)" class="material-icons icon delete-icon">delete</i>
                        </td>
                    </tr>
                </template>
            </template>
          </table>
      </div>

      <ShowEdit @update_status="handle_update_status" @close="handle_close_show_edit" v-if="show_edit_dialog" :product="selected_product" :show="selected_show"/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../../../firebase/config'
import ShowEdit from '../Shows/components/ShowEdit.vue'
import { alert,alert_confirm } from '../../../Methods/Msgs'
import use_general_storage from '../../../Methods/use_general_storage'


export default {
    components:{ShowEdit},
    setup(){
        const {delete_image_by_url}  = use_general_storage()
        const show_edit_dialog = ref(false)
        const selected_product = ref(null)
        const selected_show = ref(null)

        const selected_display_status = ref(false)
        const search = ref('')
        const products = ref([])

        const get_shows_from_db = async()=>{
            const docs = await projectFirestore.collection('Products').where('shows','!=',null).get()
            products.value = docs.docs.map(doc=>doc.data())
        }

        const handle_edit_show = (product,show)=>{
            selected_product.value = product
            selected_show.value = show
            show_edit_dialog.value = true
        }

        const handle_close_show_edit = ()=>{
            show_edit_dialog.value = false
            selected_product.value = null
            selected_show.value = null
        }

        const handle_update_status = async(copy_show)=>{
            const index = products.value.findIndex(products=>products.makat==selected_product.value.makat)
            if(index!=-1){
                const show_inx = products.value[index].shows.findIndex(show=>show.url==copy_show.url)
                if(show_inx!=-1){
                    products.value[index].shows[show_inx].approved = copy_show.approved
                }

                await projectFirestore.collection('Products').doc(products.value[index].makat)
                .set({
                    shows: products.value[index].shows
                },{merge:true})


                alert('success','הצלחה'
                ,`סטטוס שונה בהצלחה`)
                .then(()=>{
                    handle_close_show_edit()
                })



            }

        }

        const handle_delete_show = (product,show)=>{
            alert_confirm('למחוק תצוגה?')
            .then(async res=>{
                if(res.isConfirmed){
                    const index = product.shows.findIndex(_show=>_show.url==show.url)
                    if(index!=-1){
                        await delete_image_by_url(show.url)
                        product.shows.splice(index,1)
                        await projectFirestore.collection('Products').doc(product.makat)
                        .set({
                            shows: product.shows.length>0?product.shows:null
                        },{merge:true})
                    }
                }
            })
        }

        
        get_shows_from_db()


        return{
            get_shows_from_db,
            search,
            products,
            selected_display_status,
            handle_edit_show,
            show_edit_dialog,
            selected_product,
            selected_show,
            handle_close_show_edit,
            handle_update_status,
            handle_delete_show,
        }
    }
}
</script>

<style scoped>
    .show-main{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .show-main-header{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .show-main-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
    }

    .icon{
        cursor: pointer;
        margin: 0 5px;
    }
    .delete-icon{
        color: crimson;
    }
    .edit-icon{
        color: var(--warning);
    }
</style>