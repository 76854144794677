<template>
  <div class="show-edit">
      <div class="show-edit-box slide-in-top">
          <div class="show-edit-box-header">
              <h2>שינוי סטטוס מק"ט: {{product.makat}}</h2>
              <small>סוכן: {{show.agent_name}}, מס: {{show.agent_id}}</small>
          </div>
          <div class="show-edit-box-content">
            <el-select style="width:90%" v-model="copy_show.approved" placeholder="בחירת סטטוס">
                <el-option
                    v-for="status in status_options"
                    :key="status.value"
                    :label="status.label"
                    :value="status.value"
                />
            </el-select>
            <el-button @click="handle_update_status" style="width:90%; margin-top:5px;" type="success">עדכון סטטוס</el-button>
          </div>

          <div class="exit-btn" @click="handle_close">X</div>
      </div>
       
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import {slide_pop_error} from  '../../../../Methods/Msgs'

export default {
    emits:['close','update_status'],
    props:['product','show'],
    setup(props,{emit}){
        
       const status_options = ref([
           {
                value:false,
                label:'ממתין לאישור'
           },
           {
                value:true,
                label:'מאושר'
           },
        ])
       
       const copy_show = ref({...props.show})

       const handle_close = ()=>{
           emit('close')
       }

       const handle_update_status = ()=>{
           if(copy_show.value.approved == props.show.approved){
               slide_pop_error('אינך יכול לעדכן לאותו הסטטוס')
           }else{
               emit('update_status',copy_show.value)
           }
       }

      
        
        
        return{
            copy_show,
            status_options,
            handle_close,
            handle_update_status
        }
    }
}
</script>

<style scoped>
    .show-edit{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        z-index: 15;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }
    .show-edit-box{
        position: relative;
        width: 100%;
        max-width: 500px;
        height: 200px;
        background: #fff;
        border-radius: 5px;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 5px;
        left: 5px;
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }
    .show-edit-box-header{
        width: 100%;
        height: 50px;
        background: var(--success);
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 5px 5px 0 0;
        color: #fff;
        padding: 0 5px;
    }
    .show-edit-box-content{
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

</style>